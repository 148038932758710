$appt_booking: true;
$rdirection: left;
$ldirection: right;
$text-direction: rtl;
$co_updates: true;
$cr19: true; // 2019 Redesign

// password strengthen
$password_strength: true !default;

// SD SPP product
$productfull-bold-text: true;

// SCSS variable configuration to include styles for adpl.
$adpl_emea_forms_styles: true;
$adpl_il_forms_styles: true;
$link-font-size: 1.25em;
